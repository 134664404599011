import Typography from '@material-ui/core/Typography';
import React from 'react';
import { TiWarningOutline } from 'react-icons/ti';
import { withRouter } from 'react-router';
import IntlMessages from 'util/IntlMessages';
import { history } from '../../../store';
import styles from '../shared/styles/theme.scss';
import styles2 from './ReloadPage.module.scss';

function ReloadPage(props) {
    setTimeout(() => {
        if (props.location.state) {
            props.history.push(props.location.state.from);
        } else {
            history.goBack();
        }
    }, 4000);

    return (
        <div className='mt-5'>
            <Typography
                className={styles2.container}
                align='center'
                variant='h3'
                gutterBottom
            >
                <TiWarningOutline color={styles.warningColor} fontSize={60} />{' '}
                <IntlMessages id='shared.permissionChanged' />
            </Typography>
            <Typography align='center' variant='subtitle1' gutterBottom>
                <IntlMessages id='shared.redirectInProcess' />
            </Typography>
        </div>
    );
}

export default withRouter(ReloadPage);
