import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/ar-sa';

export function useLocale() {
    const { locale } = useSelector((state) => state.settings.locale);

    // neat hack used because useEffect is called after the first render
    // while use memo is called during the first render. And just as efficient
    useMemo(() => {
        if (locale === 'ar') {
            moment.defineLocale('ar-sa-mine', {
                parentLocale: 'ar-sa',
                preparse: (string) => string,
                postformat: (string) => string,
            });
        } else {
            moment.locale('en');
        }
    }, [locale]);

    return locale;
}
