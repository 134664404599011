import React from 'react';
import { NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';

// Function displays message passed to function or a default message otherwise based on message type
const showNotificationMessage = (type, message, delay, onClick = undefined) => {
    if (type === 'success') {
        return NotificationManager.success(
            message ? (
                message
            ) : (
                <IntlMessages id='notification.success.unknown' />
            ),
            <span className='text-white'>
                <IntlMessages id='notification.success' />
            </span>,
            delay ? delay : 2000,
            onClick
        );
    } else if (type === 'error') {
        return NotificationManager.error(
            message ? (
                message
            ) : (
                <IntlMessages id='notification.error.unknown' />
            ),
            <span className='text-white'>
                <IntlMessages id='notification.error' />
            </span>,
            delay ? delay : 15000,
            onClick
        );
    }
};

export default showNotificationMessage;

export const getErrorMessageByStatus = (status) => {
    switch (status) {
        case 400:
            return 'shared.error.400';
        case 404:
            return 'shared.error.404';
        case 500:
        case 502:
            return 'shared.error.500';
        default:
            return status;
    }
};

export const getErrorMessage = (message) => {
    switch (message) {
        case 'no company found':
            return 'shared.error.r01';
        case 'Authentication failed. User not found':
            return 'shared.error.r03';
        case 'Authentication failed. Wrong password':
        case 'Authenticating failed':
            return 'shared.error.r05';
        case 'You don\'t have any permissions':
            return 'shared.error.r06';
        case 'No token provided.':
            return 'shared.error.unknown';
        case 'Display not found':
            return 'shared.error.400';
        default:
            return 'shared.error.unknown';
    }
};

export const displayErrorMessage = (error, showNotification = true) => {
    let errorMessageKey;

    if (error.response && (error.response.data || error.response.status)) {
        if (error.response.data && error.response.data.error) {
            errorMessageKey = getErrorMessage(error.response.data.error);
        } else {
            errorMessageKey = getErrorMessageByStatus(error.response.status);
        }
    }

    let errorMessage;

    if (errorMessageKey) {
        errorMessage = <IntlMessages id={errorMessageKey} />;
    } else {
        errorMessage = error.message ? error.message : '';
    }

    if (showNotification) {
        showNotificationMessage('error', errorMessage);
    }

    return errorMessage;
};
