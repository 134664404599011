import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    SET_SOCKET_ID,
    SET_TOAST,
    UPDATE_USER_PREFERENCES,
    SET_IMPERSONATION_DATA,
} from 'constants/ActionTypes';
import { useSelector } from 'react-redux';

export const useSocketId = () => useSelector((state) => state.auth.soketId);

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    soketId: '',
    toastOn: true,
    parsedAuthUser:
        (localStorage.getItem('user_id') &&
            JSON.parse(localStorage.getItem('user_id'))) ||
        {},
    authUser: localStorage.getItem('user_id'),
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_TOAST: {
            return {
                ...state,
                toastOn: action.payload,
            };
        }
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
                parsedAuthUser: JSON.parse(action.payload),
            };
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
            };
        }
        case SET_SOCKET_ID: {
            return {
                ...state,
                soketId: action.payload,
            };
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload,
            };
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                initURL: window.location.pathname.includes('cs')
                    ? state.initURL
                    : '/',
                loader: false,
            };
        }
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false,
            };
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false,
            };
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true,
            };
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false,
            };
        }
        case UPDATE_USER_PREFERENCES: {
            const user = JSON.parse(state.authUser);
            user.preferences = {
                ...user.preferences,
                throughputReport: JSON.stringify(action.payload),
            };
            return {
                ...state,
                authUser: JSON.stringify(user),
            };
        }

        case SET_IMPERSONATION_DATA: {
            const oldUserData = JSON.parse(state.authUser);
            console.log({ ...oldUserData, ...action.payload });
            return {
                ...state,
                authUser: JSON.stringify({ ...oldUserData, ...action.payload }),
            };
        }

        default:
            return state;
    }
};
