import LinearProgress from '@material-ui/core/LinearProgress';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../Layout/Layout';

class IsAuthUserRoute extends Component {
    render() {
        const authUser = JSON.parse(this.props.authUser);
        const isAdmin = authUser && authUser.scope && authUser.scope.garment;
        const canActivate = this.props.canActivate?.() ?? true;
        const permissions = authUser && authUser.permissions;

        const isAllowed =
            (permissions &&
                permissions
                    .map((permission) => permission.name)
                    .some(
                        (permission) =>
                            (this.props.permissions &&
                                this.props.permissions.includes(permission)) ||
                            false
                    ) &&
                canActivate) ||
            false;

        return (
            <Route
                {...this.props}
                render={(props) =>
                    authUser ? (
                        <>
                            {this.props.auth === 'admin' ? (
                                isAdmin ? (
                                    <Suspense
                                        fallback={
                                            <div className='w-100'>
                                                <LinearProgress />
                                            </div>
                                        }
                                    >
                                        {this.props.compt}
                                    </Suspense>
                                ) : (
                                    <Layout
                                        {...props}
                                        layout={this.props.layout}
                                    >
                                        <Redirect to='/403' />
                                    </Layout>
                                )
                            ) : (
                                <Layout {...props} layout={this.props.layout}>
                                    <React.Fragment>
                                        {isAllowed ||
                                        this.props.permissions === undefined ? (
                                            this.props.compt
                                        ) : (
                                            <Redirect to='/403' />
                                        )}
                                    </React.Fragment>
                                </Layout>
                            )}
                        </>
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/signin',
                                from: this.props.location,
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mapStateToProps = ({ auth: { authUser } }) => ({ authUser });

export default connect(mapStateToProps)(IsAuthUserRoute);
