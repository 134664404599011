import memoize from 'memoizee';

const dayInMilliSeconds = 24 * 60 * 60 * 1000;

const memoizedTranslator = memoize(
    (key, userLang, authUser) => {
        const translations =
            localStorage.getItem('translations') &&
            JSON.parse(localStorage.getItem('translations'));

        if (!authUser || !userLang || !translations) return key;

        return translations[userLang][key];
    },
    { max: 1000, maxAge: dayInMilliSeconds, preFetch: true }
);

const getTranslation = (key) => {
    const userLang =
        localStorage.getItem('lang') &&
        JSON.parse(localStorage.getItem('lang')).locale;

    const authUser =
        localStorage.getItem('user_id') &&
        JSON.parse(localStorage.getItem('user_id'))._id;

    return memoizedTranslator(key, userLang, authUser);
};

export default getTranslation;
