import { __ON_PREMISE__ } from 'index';

const baseURI = `${process.env.REACT_APP_API_URL}api/`;

let BASE_URL = baseURI;
let DEVICES_BASE_URL = process.env.REACT_APP_DEVICES_BASE_URL;
let SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
let COMPANY = process.env.REACT_APP_CURRENT_COMPANY;
let LARAVEL_URL = process.env.REACT_APP_LARAVEL_URL;
let DISPLAYS_URL = process.env.REACT_APP_DISPLAYS_URL;

export const COMPANY_KEY = 'garment-devtools/company';
const BASE_URL_KEY = 'garment-devtools/baseURL';
const DEVICES_BASE_URL_KEY = 'garment-devtools/devicesBaseURL';
const SOCKET_URL_KEY = 'garment-devtools/socketUrl';
const LARAVEL_KEY = 'garment-devtools/laravel';
const DISPLAYS_KEY = 'garment-devtools/displays';

export const DEV_TOOLS_SET_URL = (
    url,
    devicesBaseURL,
    socketURL,
    laravelURL,
    displaysURL
) => {
    if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_SHOW_DEV_TOOL
    ) {
        localStorage.setItem(BASE_URL_KEY, url);
        BASE_URL = url;

        localStorage.setItem(DEVICES_BASE_URL_KEY, devicesBaseURL);
        DEVICES_BASE_URL = devicesBaseURL;

        localStorage.setItem(SOCKET_URL_KEY, socketURL);
        SOCKET_URL = socketURL;

        localStorage.setItem(LARAVEL_KEY, laravelURL);
        LARAVEL_URL = laravelURL;

        localStorage.setItem(DISPLAYS_KEY, displaysURL);
        DISPLAYS_URL = displaysURL;
    }
};

export const DEV_TOOLS_SET_COMPANY = (company) => {
    console.log('setting company beginning');
    if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_SHOW_DEV_TOOL
    ) {
        console.log('setting company passed if condition');
        localStorage.setItem(COMPANY_KEY, company);
        COMPANY = company;
        console.log('set company');
    }
};

if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_SHOW_DEV_TOOL
) {
    BASE_URL = localStorage.getItem(BASE_URL_KEY) ?? baseURI;
    localStorage.setItem(BASE_URL_KEY, BASE_URL);

    DEVICES_BASE_URL =
        localStorage.getItem(DEVICES_BASE_URL_KEY) ?? DEVICES_BASE_URL;
    localStorage.setItem(DEVICES_BASE_URL_KEY, DEVICES_BASE_URL);

    SOCKET_URL = localStorage.getItem(SOCKET_URL_KEY) ?? SOCKET_URL;
    localStorage.setItem(SOCKET_URL_KEY, SOCKET_URL);

    LARAVEL_URL = localStorage.getItem(LARAVEL_KEY) ?? LARAVEL_URL;
    localStorage.setItem(LARAVEL_KEY, LARAVEL_URL);

    DISPLAYS_URL = localStorage.getItem(DISPLAYS_KEY) ?? DISPLAYS_URL;
    localStorage.setItem(DISPLAYS_KEY, DISPLAYS_URL);

    COMPANY = localStorage.getItem(COMPANY_KEY) ?? COMPANY;
    localStorage.setItem(COMPANY_KEY, COMPANY);
}

export const GET_BASE_URL = () => BASE_URL;

export const GET_DEVICES_BASE_URL = () => DEVICES_BASE_URL;

export const GET_SOCKET_URL = () => SOCKET_URL;

export const GET_LARAVEL_URL = () => LARAVEL_URL;

export const GET_DISPLAYS_URL = () => DISPLAYS_URL;

export const GET_COMPANY = () =>
    __ON_PREMISE__ ? localStorage.getItem(COMPANY_KEY) : COMPANY;
