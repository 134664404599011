import Drawer from '@material-ui/core/Drawer';
import {
    setDrawerType,
    toggleCollapsedNav,
    updateWindowWidth,
} from 'actions/Setting';
import {
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    MINI_DRAWER,
} from 'constants/ActionTypes';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import UserInfo from '../UserInfo/UserInfo';
import styles from './SideNav.module.scss';
import SidenavContent from './SidenavContent';

class SideNav extends React.PureComponent {
    // remove type and open from state
    state = {
        drawerType: this.props.drawerType,
        type: 'permanent',
        open: true,
    };

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth);
        });
    }

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    render() {
        const { navCollapsed, drawerType, width, navigationStyle } = this.props;
        let drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? 'd-xl-flex'
            : drawerType.includes(COLLAPSED_DRAWER)
            ? ''
            : 'd-flex';
        let type = this.state.type;
        if (
            drawerType.includes(COLLAPSED_DRAWER) ||
            ((drawerType.includes(FIXED_DRAWER) ||
                drawerType.includes(MINI_DRAWER)) &&
                width < 1200)
        ) {
            type = 'temporary';
            if (!drawerType.includes(FIXED_DRAWER)) {
                this.props.setDrawerType(FIXED_DRAWER);
            }
        }

        if (navigationStyle === HORIZONTAL_NAVIGATION) {
            drawerStyle = '';
            type = 'temporary';
        }
        return (
            <div className={`app-sidebar d-none ${drawerStyle} `}>
                <Drawer
                    className='app-sidebar-content'
                    variant={type}
                    open={type.includes('temporary') ? navCollapsed : false}
                    onClose={this.onToggleCollapsedNav}
                    classes={{
                        paper: 'side-nav',
                    }}
                >
                    <Link className={'app-logo mr-2 d-block'} to='/'>
                        <img
                            width='90%'
                            id='logoImage'
                            src={
                                this.props.drawerType === FIXED_DRAWER
                                    ? require('assets/images/logo_horizontal_noslogan.svg')
                                    : require('assets/images/garment-logo-mini.svg')
                            }
                            alt='Company Logo'
                            title='GarmentIO'
                            className={`${
                                this.props.isDirectionRTL
                                    ? styles.logoRtlMargin
                                    : styles.logoMargin
                            }
								${this.props.drawerType === FIXED_DRAWER ? styles.logoImageSize : 'my-3'}
                                `}
                        />
                    </Link>
                    {this.props.data.length ? (
                        <SidenavContent
                            data={this.props.data}
                            showLogoutMenu={this.props.showLogoutMenu}
                            drawerType={this.props.drawerType}
                            navCollapsed={this.props.navCollapsed}
                        />
                    ) : null}

                    <UserInfo showMenu={true} />
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const {
        navCollapsed,
        drawerType,
        width,
        navigationStyle,
        locale,
        isDirectionRTL,
    } = settings;
    return {
        navCollapsed,
        drawerType,
        width,
        navigationStyle,
        locale,
        isDirectionRTL,
    };
};

export default withRouter(
    connect(mapStateToProps, {
        toggleCollapsedNav,
        setDrawerType,
        updateWindowWidth,
    })(SideNav)
);
